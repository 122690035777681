export default {
  title: 'Negócios',
  menu: [
    /*{
      id: 'financeiro-contas',
      title: 'Contas',
      icon: 'money-check',
      style: 'light',
      url: '/#/financeiro/contas'
    },
    {
      id: 'cc',
      title: 'Centro de Custo',
      icon: 'clipboard-list-check',
      style: 'regular',
      url: '/#/financeiro/cc'
    },
    {
      id: 'relatorios',
      title: 'Relatórios',
      icon: 'user-chart',
      style: 'regular',
      url: '#'
    }*/
  ]
}
